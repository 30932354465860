import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/Alarm_Notification/NavButtons';
import ForumBox from 'components/Motion_Detection/Alarm_Notification/ForumBox';
import TimeLine from 'components/Products/CommentList/HomekitAlexaList';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Notification",
  "path": "/Motion_Detection/Alarm_Notification/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Setting up and activating the email alarm.",
  "image": "./MD_SearchThumb_AlarmNotification_1080p.png",
  "social": "/images/Search/MD_SearchThumb_AlarmNotification_1080p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Alarm-Notification_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Alarm Notification' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Setting up and activating the email alarm.' image='/images/Search/MD_SearchThumb_AlarmNotification_1080p.png' twitter='/images/Search/MD_SearchThumb_AlarmNotification_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Alarm_Benachrichtigung/' locationFR='/fr/Motion_Detection/Alarm_Notification/' crumbLabel="Notifications" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "alarm-notification",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#alarm-notification",
        "aria-label": "alarm notification permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Notification`}</h1>
    <h2 {...{
      "id": "1080p-camera-series",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1080p-camera-series",
        "aria-label": "1080p camera series permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Camera Series`}</h2>
    <p>{`Setting up and activating the email alarm.`}</p>
    <h3 {...{
      "id": "step-01",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-01",
        "aria-label": "step 01 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 01`}</h3>
    <p>{`First, open the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/Email/"
      }}>{`Email Settings`}</a>{`. INSTAR offers you a forwarding Email Server, that allows your camera to send email notifications - `}<em parentName="p">{`TLS encrypted`}</em>{` - to your personal email address. We recommend that you `}<strong parentName="p">{`select INSTAR from the SMTP preset`}</strong>{` menu and apply those settings. Then switch to the recipient tab and type in your personal email address, subject and email text - `}<strong parentName="p">{`DONE`}</strong>{`!`}</p>
    <p>{`You can, of course, also use your personal provider's SMTP Server information instead. We collected a few `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Alarm_Notification/SMTP_Servers/"
      }}>{`HERE`}</a>{`. Please verify them on your provider's web site and check out our `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Alarm_Notification/Troubleshooting/"
      }}>{`Troubleshooting Guide`}</a>{`, if you run into problems.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "601px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/277d276b39c9a66ac1f1ade9bdbfbe04/d8f62/Alarm_Notification_1080p_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACHUlEQVQ4y4VTbWsTQRC+nxkRSms/GMFXTBU/KFh8+R+JUqkoFGlTraV+E0GT+vJFm7RRQeH2mvZ2b3Znd/ZF7ja1hrTxYTgYdp59duaZS64sv7/0/MvsUnduaXv+yfbZR91a80MZrc6ZKmqtTq3ZiWmt2Zl53K0//XTx2ef68sfkwcbXh29+3H61e2dj7+7rwc12r9Hu31jfW2j3G2u9KvoL7d3GWpleX+3dWu8vbg7ubw0WNwcJO8jRemWsIquMNc7nAn6nLBdgnNfWcZDDQy5Aaeu0dWidIier+gSgCOMwiIXgWqkq82SMRnTWhgkkRVFYa3UFIvLeTxYRESJaa90RiGhENsYgojHGnnR9CEFKyTmPNUQU60uyEAIRlVKnMUMIiAgAUkqttXPOWnusrLVWSjnnppC11t57e4TYYEl2ziHiFLJSSggRhxJhNI6UvfdENIXsvf/31HnPkbw/eraUUo28+T8KY9/9zJHcyCpjzIkm/e25KAoAiNbEgR9bNV029gwA0c6xaSOilBKVived6HPOeQEQaWPK5Qyq8KdPLHhXxsR6ioDDAGmQrPz6sVXR5DIwQ0mZJIFEjJk0NSkzKQveJ8APwsvLYWUmvDgXVmaD3I9arnpG55e4utq7tzW41t5tvf0+mL/wbfb8zlx9Z67upEwYY1rsY54izzBnqGT8ScpN1hoU7nN5IGTG4ZAXwDJgWZEyYJkE+AP/bO4Vc4e1IwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/277d276b39c9a66ac1f1ade9bdbfbe04/e4706/Alarm_Notification_1080p_01.avif 230w", "/en/static/277d276b39c9a66ac1f1ade9bdbfbe04/d1af7/Alarm_Notification_1080p_01.avif 460w", "/en/static/277d276b39c9a66ac1f1ade9bdbfbe04/2047d/Alarm_Notification_1080p_01.avif 601w"],
              "sizes": "(max-width: 601px) 100vw, 601px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/277d276b39c9a66ac1f1ade9bdbfbe04/a0b58/Alarm_Notification_1080p_01.webp 230w", "/en/static/277d276b39c9a66ac1f1ade9bdbfbe04/bc10c/Alarm_Notification_1080p_01.webp 460w", "/en/static/277d276b39c9a66ac1f1ade9bdbfbe04/84ad9/Alarm_Notification_1080p_01.webp 601w"],
              "sizes": "(max-width: 601px) 100vw, 601px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/277d276b39c9a66ac1f1ade9bdbfbe04/81c8e/Alarm_Notification_1080p_01.png 230w", "/en/static/277d276b39c9a66ac1f1ade9bdbfbe04/08a84/Alarm_Notification_1080p_01.png 460w", "/en/static/277d276b39c9a66ac1f1ade9bdbfbe04/d8f62/Alarm_Notification_1080p_01.png 601w"],
              "sizes": "(max-width: 601px) 100vw, 601px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/277d276b39c9a66ac1f1ade9bdbfbe04/d8f62/Alarm_Notification_1080p_01.png",
              "alt": "Alarm Notification Setup for the 1080p Camera Series",
              "title": "Alarm Notification Setup for the 1080p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`All those information will be filled automatically, if you choose the INSTAR SMTP Preset - otherwise:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SMTP Server`}</strong>{`: Here you have to fill in the SMTP Server from your email provider. Mostly you can find this data on the website of your email provider by searching for SMTP. Otherwise you find a list `}<a parentName="li" {...{
          "href": "/en/Motion_Detection/Alarm_Notification/SMTP_Servers"
        }}>{`Here`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`SMTP Port`}</strong>{`: Fill in the Port which your email provider is using. Normally this will be either Port 25, 465 or 587.`}</li>
      <li parentName="ul"><strong parentName="li">{`SSL`}</strong>{`: Here you can activate if your account is using SSL encryption.`}</li>
      <li parentName="ul"><strong parentName="li">{`SMTP Login Type`}</strong>{`: For almost all email servers this has to be set to Authentication so that you can identify yourself with your username and password.`}</li>
      <li parentName="ul"><strong parentName="li">{`SMTP Username`}</strong>{`: Please fill in the username of your email account.`}</li>
      <li parentName="ul"><strong parentName="li">{`SMTP Password`}</strong>{`: Please fill in the password of your email account.`}</li>
    </ul>
    <p>{`Please enter your email accounts SMTP data and your personal login and save all settings by clicking Apply.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/284230641b1223b34a9de05dc3a467f6/0a47e/Alarm_Notification_1080p_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "86.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAByUlEQVQ4y5WS32sTQRDH7980EfFNH4S+iPiqqdC+qP+BjzYtNKUtpq8GER98UAp3Se4sNMUEzZ1Jb2/3dmb2h+yuNTE0xX74Mswu82V2ho2eHsdPTs4edAYPO8NHh+m9vaTRjpu7LjZ2nJrt5O5uv9lOGjvxnbfx/b3+xlH2+F22cZxGX8+nWVGnOQ/6lovBDxaPZ8OfLMtFVoh4Mj+9yJPJ3B290pwPpzyd8qgW3P6LIgRRKYRwlLxiswLr1TJrbVQypoxBUqSUMkZpJ21sSFzuRaRRKdL6r7QxUVVViCilBABjjL2Ouq7Lsqw8iEhEiOg6V1UFAHVda63tGgBACFF7QkJEtzBLT/Ajoh/RP5uIAOBmc+gZAAAiWpillOEl1yKlDJ5AGNuZGWMAwDkHgHVm5Vm+Cat1nZVSobNeg/GsXC7MYSWIaP6Pq4UxZo0ymrzUSpG++jbhtxgio5Sz+jaus5thWUvYG4kqdmmzrk32bf/ARRJ/VuLjuITuoOidzbrDX19Gs/nBUd5xKvYPDVLEy7n99NK+f2Z7m7bXsvJyaZ12MOWvPly8+Tx+/fF793QyfrF9/nxr1Noetba0lFGe54va2yCE+A1yrMNtZdLQhgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/284230641b1223b34a9de05dc3a467f6/e4706/Alarm_Notification_1080p_02.avif 230w", "/en/static/284230641b1223b34a9de05dc3a467f6/d1af7/Alarm_Notification_1080p_02.avif 460w", "/en/static/284230641b1223b34a9de05dc3a467f6/56a33/Alarm_Notification_1080p_02.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/284230641b1223b34a9de05dc3a467f6/a0b58/Alarm_Notification_1080p_02.webp 230w", "/en/static/284230641b1223b34a9de05dc3a467f6/bc10c/Alarm_Notification_1080p_02.webp 460w", "/en/static/284230641b1223b34a9de05dc3a467f6/e88ff/Alarm_Notification_1080p_02.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/284230641b1223b34a9de05dc3a467f6/81c8e/Alarm_Notification_1080p_02.png 230w", "/en/static/284230641b1223b34a9de05dc3a467f6/08a84/Alarm_Notification_1080p_02.png 460w", "/en/static/284230641b1223b34a9de05dc3a467f6/0a47e/Alarm_Notification_1080p_02.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/284230641b1223b34a9de05dc3a467f6/0a47e/Alarm_Notification_1080p_02.png",
              "alt": "Alarm Notification Setup for the 1080p Camera Series",
              "title": "Alarm Notification Setup for the 1080p Camera Series",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p>{`Now switch to the Recipient Tab and add your personal Email Address, an Email Subject and Bodytext and Apply. You can click on the Test button to verify your settings.`}</p>
    <h3 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h3>
    <p>{`Now follow our `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Setup/"
      }}>{`Motion Detection Setup Guide`}</a>{` to activate the Motion Detection and Alarm Notification.`}</p>
    <EuiSpacer size="xl" mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer size="s" mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      